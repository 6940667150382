import {AfterViewInit, Component, OnDestroy, ViewEncapsulation} from "@angular/core";
import {Subscription} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";
import {AppService, ExtJsService, RouterUtil, ViewChangeEvent, ViewportService, LicenseService} from "@cafe/web-ui-framework";
import {RuleLibraryService} from "@cafe/bom-group";

@Component({
    selector: "dqp-app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewInit, OnDestroy {
    title = "Data360 DQ+";

    private _showTopNav = true;
    public isShowingExtJsView = true;

    private subscriptions = new Subscription();

    constructor(private viewportService: ViewportService, private router: Router, private extjsService: ExtJsService,
                private appService: AppService, private routerUtil:RouterUtil,
                private licenseService: LicenseService,
                private ruleLibraryService: RuleLibraryService) {
        this.subscriptions.add(viewportService.viewChange.subscribe((viewChangeEvent: ViewChangeEvent) => {
            this.isShowingExtJsView = viewChangeEvent.isShowingExtJs;
        }));

        this.subscriptions.add(router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.showTopNav = true;
                const url = event.urlAfterRedirects;
                this.isShowingExtJsView = !this.routerUtil.isSupportedUrl(url);
                this.viewportService.isShowingExtJsView = this.isShowingExtJsView;
                if (this.isShowingExtJsView) {
                    this.viewportService.notifyLayoutChange();
                }
            }
        }));
    }

    get isPortalMode(): Boolean {
        return this.appService.isPortalMode;
    }

    get showTopNav(): boolean {
        return this._showTopNav;
    }

    set showTopNav(value: boolean) {
        this._showTopNav = value;
    }

    public onRouterOutletActivate(routerComponent: any) {
        this.viewportService.currentViewportComponent = routerComponent;
    }

    public onRouterOutletDeactivate(routerComponent: any) {
        this.viewportService.currentViewportComponent = null;
    }

    ngAfterViewInit(): void {
        if (window.location.hash === "#logout") {
            setTimeout(()=>{
                this.showTopNav = false;
            })
        }

        this.subscriptions.add(
            this.extjsService.onExtJsViewportEvent("contentviewchange").subscribe(
                ([viewport, newView, oldView])=> {
                    const showNav: boolean = newView.xtype !== "igx-loginview" && newView.xtype !== "igx-logoutview";
                    this.showTopNav = showNav;
                })
        );

        this.subscriptions.add(
            this.extjsService.onExtJsEvent("showThirdPartyLicensePopup").subscribe(([isEnterpriseDeployment])=>{
                this.licenseService.showThirdPartyLicensePopup(isEnterpriseDeployment);
            })
        )
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
