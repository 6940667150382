<div class="ig-form-field ig-w-100pc">
    <label *ngIf="isReadOnly;else notReadOnly">{{getFormControl().value}}</label>
    
    <ng-template #notReadOnly>
        <ng-container *ngIf="!isInAdderRow();else inAdderRow">
            <!--In Regular Row-->
            <input type="text" pInputText
                   [formControl]="getFormControl()"
                   [placeholder]="columnConfig.required ? (''|IgConstants).REQUIRED : ''"
                   [class.ig-required]="hasFieldRequiredError()"
            />
        </ng-container>
        <ng-template #inAdderRow>
            <!-- In Adder Row-->
            <input type="text" pInputText
                   [formControl]="getFormControl()"
                   [class.adder-first-input]="isFirstColumn()"
                   [placeholder]="getAdderPlaceholder()"
                   [class.ig-required]="hasAdderFieldRequiredError()"
                   (blur)="onAdderTextInputBlur($event)"
                   (keydown)="onAdderTextInputKeyDown($event)"
            />
        </ng-template>
        <ng-container *ngIf="isFieldInvalid()">
            <span class="ig-feedback">{{getFieldErrorMsg()}}</span>
        </ng-container>

    </ng-template>
    
</div>